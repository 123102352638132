import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import { useEffect, useRef, useState } from "react";
import { useIsVisible } from "react-is-visible";
import { Link } from "react-router-dom";
import SwiperRaw from "swiper";
import "swiper/css";
import { Autoplay } from "swiper/modules";
import { Swiper, SwiperRef, SwiperSlide, useSwiper } from "swiper/react";
import { twJoin, twMerge } from "tailwind-merge";

type Props = {
	data: ExploreData;
	className?: string;
	autoplay?: number;
};

type ExploreTile = {
	title: string;
	link: string;
	image: string;
	size: number;
	bgPosition?: string;
	button?: string;
};

type ExploreSlide = {
	title: string;
	items: Array<ExploreTile>;
};

export type ExploreData = Array<ExploreSlide>;

// https://github.com/nolimits4web/swiper/issues/5577#issuecomment-1476587892
export const useSwiperReactive = () => {
	const swiper = useSwiper();

	// State to force a rerender.
	const [, setSignal] = useState({});
	const forceRerender = () => setSignal({});

	useEffect(() => {
		swiper.on("realIndexChange", forceRerender);
		//swiper.on("reachBeginning", forceRerender);
		//swiper.on("reachEnd", forceRerender);
		return () => {
			swiper.off("realIndexChange", forceRerender);
			//swiper.off("reachBeginning", forceRerender);
			//swiper.off("reachEnd", forceRerender);
		};
	}, [swiper]);

	return swiper;
};

function SlideProgress(props: { swiper: SwiperRaw; active: boolean }) {
	const { swiper, active } = props;
	const [progress, setProgress] = useState(100);

	useEffect(() => {
		if (!active) {
			setProgress(1);
			return;
		}
		function onTimeLeft(_a: any, _b: any, p: number) {
			setProgress(Math.abs(p - 1));
		}
		swiper.on("autoplayTimeLeft", onTimeLeft);
		return () => swiper.off("autoplayTimeLeft", onTimeLeft);
	}, [active]);

	return (
		<div className="mx-auto h-[2px] w-[60%] overflow-hidden rounded bg-black/20">
			<div
				className="mx-auto h-full w-full origin-left bg-black/50"
				style={{ transform: `scaleX(${progress})` }}
			/>
		</div>
	);
}

function PageButton(props: {
	swiper: SwiperRaw;
	item: ExploreSlide;
	idx: number;
}) {
	const { swiper, idx, item } = props;
	const swiperNav = useSwiperReactive();

	return (
		<button
			className={twJoin(
				"space-y-2",
				"font-display text-xs uppercase tracking-wider",
				"mb-4 p-4 text-sm font-normal md:w-60 ",
				"focus:outline-none",
				swiper.realIndex === idx ? "text-black" : "text-gray-[#A19D9D]",
			)}
			onClick={() => {
				if (swiper.realIndex !== idx) {
					swiper.slideToLoop(idx);
					swiperNav.slideTo(idx);
					if (swiper.autoplay.running) {
						swiper.autoplay.start();
					}
				}
			}}
		>
			<span>{item.title}</span>
			<SlideProgress swiper={swiper} active={swiper.realIndex === idx} />
		</button>
	);
}

function Pagination(props: { items: ExploreSlide[] }) {
	const ref = useRef<SwiperRef>(null);
	const swiper = useSwiperReactive();

	useEffect(() => {
		ref.current?.swiper.slideTo(swiper.realIndex);
	}, [swiper.realIndex]);

	return (
		<Swiper
			ref={ref}
			cssMode
			className="relative w-full max-w-fit"
			spaceBetween={5}
			centeredSlides
			slidesPerView="auto"
			breakpoints={{ 641: { spaceBetween: 20, centeredSlides: false } }}
			onBreakpoint={(s) => {
				/**
				 * Workaround bug as swiper is not re-applying
				 * the centered class on breakpoint change
				 */
				const w = window.innerWidth <= 640;
				s.el.classList.toggle("swiper-centered", w);
			}}
		>
			<div slot="container-start">
				<div className="pointer-events-none absolute left-0 top-0 z-10 h-full w-10 bg-gradient-to-r from-white to-white/0" />
				<div className="pointer-events-none absolute right-0 top-0 z-10 h-full w-10 bg-gradient-to-l from-white to-white/0" />
			</div>
			{props.items.map((item, idx) => (
				<SwiperSlide key={item.title} className="w-max">
					<PageButton swiper={swiper} item={item} idx={idx} />
				</SwiperSlide>
			))}
		</Swiper>
	);
}

function NavButton({ next }: { next?: boolean }) {
	const swiper = useSwiperReactive();
	return (
		<button
			className={twJoin(
				"hidden md:block",
				"absolute top-1/2 z-50 translate-y-2 bg-white p-2",
				next ? "right-0" : "left-0",
				// ((swiper.isEnd && next) || (swiper.isBeginning && !next)) && "opacity-50",
			)}
			onClick={() => {
				next ? swiper.slideNext() : swiper.slidePrev();
				if (swiper.autoplay.running) {
					swiper.autoplay.start();
				}
			}}
		>
			{next ? (
				<ChevronRightIcon className="h-10 w-10" />
			) : (
				<ChevronLeftIcon className="h-10 w-10" />
			)}
		</button>
	);
}

function Tile(props: { item: ExploreTile; slideIndex: number }) {
	const { item, slideIndex } = props;
	const ref = useRef(null);
	const isVisible = useIsVisible(ref);
	const [loaded, setLoaded] = useState(false);
	const swiper = useSwiperReactive();

	useEffect(() => {
		if (loaded) return;
		if (!isVisible || swiper.realIndex !== slideIndex) return;
		const img = new Image(0, 0);
		img.onload = () => setLoaded(true);
		img.src = item.image;
	}, [isVisible, item.image, swiper.realIndex]);

	return (
		<Link
			ref={ref}
			key={item.title}
			to={item.link}
			className={twJoin(
				"group relative",
				"h-screen max-h-[48vh] w-full",
				"grid items-end",
				"p-10",
				"overflow-hidden",
				"col-span-12",
				item.size === 4 && "md:col-span-6 xl:col-span-4",
				item.size === 5 && "md:col-span-6 xl:col-span-5",
				item.size === 6 && "md:col-span-6",
				item.size === 7 && "md:col-span-6 xl:col-span-7",
				item.size === 8 && "md:col-span-6 xl:col-span-8",
			)}
		>
			<div
				className={twJoin(
					"absolute inset-0",
					"bg-neutral-100 bg-cover",
					"transition-all duration-1000 ease-out",
					"group-hover:scale-105",
					loaded ? "opacity-100" : "opacity-0",
				)}
				style={{
					backgroundImage: loaded ? `url(${item.image})` : "",
					backgroundPosition: item.bgPosition || "center top",
				}}
			/>
			<div
				className={twJoin(
					"absolute inset-0",
					"bg-gradient-to-tr from-black/70 via-black/20 to-black/0",
				)}
			/>
			<div className="relative grid gap-2 text-white">
				<h3 className="ln-subtitle whitespace-pre-line text-base font-normal">
					{item.title}
				</h3>
				{item.button && (
					<span
						className={twJoin(
							"button mx-0 w-max border bg-transparent text-center text-xs",
							"group-hover:border-white group-hover:bg-white group-hover:text-black",
						)}
					>
						{item.button}
					</span>
				)}
			</div>
		</Link>
	);
}

export default function Explore(props: Props) {
	const { data, autoplay } = props;
	return (
		<section className={twMerge("space-y-4", props.className)}>
			<h2 className="font-display pt-10 text-center text-xl tracking-wide">
				Explore Launer
			</h2>
			<Swiper
				cssMode
				centeredSlides
				slidesPerView={1}
				modules={[Autoplay]}
				className="relative overflow-visible"
				autoplay={
					autoplay
						? {
								delay: autoplay,
								disableOnInteraction: true,
								pauseOnMouseEnter: true,
						  }
						: false
				}
				loop
			>
				<div slot="container-start">
					<Pagination items={data} />
				</div>
				<div slot="container-end">
					<NavButton />
					<NavButton next />
				</div>
				{data.map((tab, tabIndex) => (
					<SwiperSlide
						key={tab.title}
						className={twJoin("grid grid-cols-12 gap-1 bg-white")}
					>
						{tab.items.map((item) => (
							<Tile
								key={item.title}
								item={item}
								slideIndex={tabIndex}
							/>
						))}
					</SwiperSlide>
				))}
			</Swiper>
		</section>
	);
}

import {
	Explore,
	Instagram,
	ProductCarousel,
	Row,
	ThreeSixty,
} from "@launerlondon/shop-components";
import { useMetaTags } from "@launerlondon/shop-hooks";
import { Link } from "react-router-dom";
import { twJoin, twMerge } from "tailwind-merge";

type SubtitleProps = React.PropsWithChildren & { className?: string };

const Subtitle: React.FC<SubtitleProps> = (props) => {
	return (
		<h4
			className={twMerge(
				"ln-subtitle-sans pt-2 text-xs tracking-[0.35em]",
				props.className,
			)}
		>
			{props.children}
		</h4>
	);
};

export default function Home() {
	useMetaTags({
		title: "Launer London | Handmade British Luxury Leather Bags & Goods",
		description:
			"Discover Launer's British handcrafted leather bags, wallets, purses and accessories. Heritage creations crafted from the finest leather since 1941. Shop now.",
		image: "/images/pages/home-banner.webp",
		suffix: false,
	});
	const accentRowBg = "bg-[#FBE5E6]";
	const accentRowFg = "text-[#915D5D]";
	return (
		<div>
			<div className="relative">
				<div
					className={twJoin(
						"absolute inset-x-0 top-0 z-10",
						"p-2",
						"text-center font-light leading-relaxed text-white",
						"text-[9px] lg:text-[10px]",
						"uppercase tracking-widest",
						"bg-[#343030] md:bg-[#343030]/70",
					)}
				>
					Complimentary shipping on all handbags and luggage /
					Complimentary lettering for a limited period
				</div>
				<Row.WithImage
					image="/images/pages/home-banner.webp"
					className="bg-[#FAB0B1] text-white max-sm:text-sm"
					imageClassName="bg-right"
					contentClassName={twJoin(
						"place-self-stretch sm:max-w-full",
						"text-center",
						"flex flex-col items-center justify-center",
						"gap-4 xl:gap-8",
						"bg-gradient-to-r from-[#BE4E51] to-[#FAB0B1]",
					)}
				>
					<h1 className="ln-title flex max-w-[350px] flex-col text-4xl">
						Inspired Valentine’s gifts for him and her
					</h1>
					<p className="mx-auto max-w-[320px]">
						Celebrate romance with Launer’s handmade gifts, tokens
						of love crafted from the finest leather for treasured
						memories.
					</p>
					<Link
						to="/cc/valentines-day"
						className={twJoin(
							"block",
							"mx-auto w-max px-10 py-4",
							"border opacity-80 transition hover:opacity-100",
							"ln-subtitle-sans text-xs tracking-[0.35em]",
							"font-normal",
						)}
					>
						Visit
					</Link>
				</Row.WithImage>
			</div>
			<div
				className={twJoin(
					"container mt-1",
					"grid grid-cols-1 gap-1 md:grid-cols-8",
					"text-center uppercase tracking-widest",
				)}
			>
				<Link
					to="/cc/valentines-day"
					className={twJoin(
						"order-2",
						"hidden md:block",
						"md:col-span-4",
						"xl:aspect-[4/4]",
						"bg-cover bg-top",
						"bg-[url('/images/pages/home-carousel.webp')]",
					)}
				/>
				<div
					className={twJoin(
						"order-1",
						"md:col-span-4",
						"bg-gradient-to-br from-gray-50 to-gray-200",
						"grid place-items-center gap-10 py-10",
					)}
				>
					<Link to="/cc/valentines-day" className="block">
						<h3 className="font-display text-xl normal-case tracking-wide">
							Valentine’s Edit
						</h3>
						<Subtitle className="text-xs">View</Subtitle>
					</Link>
					<ProductCarousel
						products={[
							"3050-33",
							"620-03",
							"686-14",
							"3011-08",
							"805-26",
							"670-02",
							"717-12",
							"2914-09",
						]}
					/>
				</div>
				<div
					className={twJoin(
						"_xl:order-3 order-3",
						"md:col-span-4",
						"xl:col-span-2",
						"flex flex-col gap-2",
						"p-5 text-sm leading-relaxed",
						"bg-[#F3ECE4]",
					)}
				>
					<div
						className={twJoin(
							"relative mx-auto bg-cover bg-right bg-top",
							"w-full flex-1",
							//"aspect-square",
							"text-[#343030]",
							"bg-gradient-to-b from-[#EFE7DD] to-[#DBD0BB]",
							"grid place-items-center",
							"normal-case tracking-wide",
						)}
					>
						<div className="mb-20 flex flex-col items-center justify-center gap-10 py-10">
							<h3 className="font-display max-w-[200px] text-xl font-medium">
								New year’s Greeting
							</h3>
							<div className="h-1 w-14 border-b border-black/50" />
							<figure className="space-y-10 px-8">
								<blockquote>
									<p className="text-base">
										“On behalf of Gerald Bodmer CEO and all
										at Launer, we want to extend our best
										wishes for the New Year and to thank you
										all for your continued support which is
										greatly appreciated”
									</p>
								</blockquote>
							</figure>
						</div>
					</div>
				</div>
				<Link
					to="/cc/the-hatherton-collection"
					className={twJoin(
						"order-4",
						"md:col-span-8",
						"xl:col-span-4",
						"flex flex-col gap-2",
						"p-5 text-sm leading-relaxed",
						accentRowBg,
					)}
				>
					<div className=" mb-5 flex flex-col items-center justify-center md:h-16">
						<h3 className="font-display mx-auto block tracking-widest text-[#343030]">
							THE HATHERTON COLLECTION
						</h3>
						<Subtitle className={accentRowFg}>
							Our new range of refined luggage pieces
						</Subtitle>
					</div>
					<div
						className={twJoin(
							"aspect-[4/3]",
							"bg-cover bg-center",
							"relative",
							"bg-[url('/images/pages/home-feat-2.webp')]",
						)}
					/>
				</Link>
				<Link
					to="/p/3097-20/judi-burgundy-croc-effect"
					className={twJoin(
						"order-3 xl:order-5",
						"md:col-span-4",
						"xl:col-span-2",
						"relative",
						"flex flex-col gap-2",
						"p-5 text-sm leading-relaxed",
						accentRowBg,
					)}
				>
					<div className="mb-5 flex flex-col items-center justify-center md:h-16">
						<h3 className="font-display mx-auto block max-w-[300px] tracking-widest text-[#343030]">
							Faux Croc
						</h3>
						<Subtitle className={accentRowFg}>
							Judi in Beautiful Burgundy
						</Subtitle>
					</div>
					<div
						className={twJoin(
							"relative mx-auto",
							"bg-[#FFF7F8] bg-cover bg-bottom bg-no-repeat xl:bg-contain",
							"w-full flex-1",
							"aspect-square",
							"bg-[url(/images/pages/home-feat-3.webp)]",
						)}
					/>
				</Link>
			</div>
			<Explore
				autoplay={10000}
				className="container"
				data={[
					{
						title: "Latest at Launer",
						items: [
							{
								title: "The Stunning\nSuede Judi",
								link: "/p/3097-30/judi-tan-suede",
								image: "/images/pages/home-explore-suede-judi.webp",
								size: 6,
								button: "Shop Now",
								bgPosition: "center",
							},
							{
								title: "Radiate in Green\nThis Winter",
								link: "/cc/radiate-in-green-this-winter",
								image: "/images/pages/home-explore-radiate-in-green.webp",
								size: 6,
								button: "View Edit",
								bgPosition: "center bottom",
							},
							{
								title: "Perfect\nParty Bags",
								link: "/cc/perfect-party-bags",
								image: "/images/pages/home-explore-perfect-party-bags.webp",
								size: 7,
								button: "View Edit",
								bgPosition: "bottom",
							},
							{
								title: "Animal\nMagic",
								link: "/cc/leopard",
								image: "/images/pages/home-explore-animal-magic.webp",
								size: 5,
								button: "View Edit",
								bgPosition: "center top",
							},
						],
					},
					{
						title: "Highlights",
						items: [
							{
								title: "Royal Tartan",
								link: "/cc/royal-tartan",
								button: "View Edit",
								image: "/images/pages/home-explore-royal-tartan.webp",
								size: 7,
								bgPosition: "bottom",
							},
							{
								title: "Clever Colour\nCombinations",
								link: "/cc/clever-colour-combinations",
								button: "View Edit",
								image: "/images/pages/home-explore-clever-colour-combinations.webp",
								size: 5,
								bgPosition: "center",
							},
							{
								title: "Patent\nWonder",
								link: "/cc/patent-wonder",
								button: "Shop now",
								image: "/images/pages/home-explore-patent-wonder.webp",
								size: 6,
								bgPosition: "center",
							},
							{
								title: "Accessories\nto Cherish",
								link: "/c/accessories",
								button: "Shop now",
								image: "/images/pages/home-explore-accessories.webp",
								size: 6,
								bgPosition: "center",
							},
						],
					},
					{
						title: "Customisation",
						items: [
							{
								title: "Choose from over 100\ndifferent colours and finishes",
								link: "/customise-at-launer",
								image: "/images/pages/home-explore-swatches.webp",
								size: 8,
								bgPosition: "bottom",
							},
							{
								title: "Personalised\nLettering",
								link: "/customise-at-launer",
								image: "/images/pages/home-explore-lettering.webp",
								size: 4,
								bgPosition: "center top",
							},
							{
								title: "A choice of\nequisite fittings",
								link: "/customise-at-launer",
								image: "/images/pages/home-explore-fittings.webp",
								size: 5,
								bgPosition: "center top",
							},
							{
								title: "Elevate with a\nstatement strap",
								link: "/customise-at-launer",
								image: "/images/pages/home-explore-straps.webp",
								size: 7,
								bgPosition: "center",
							},
						],
					},
				]}
			/>
			<div className="container my-20 text-center uppercase tracking-widest">
				<h3 className="text-lg font-light">
					<Link
						to="/p/3050-35/traviata-scarlet-royal-stewart-tartan"
						className="text-[#BBACA0]"
					>
						Scarlet / Tartan Traviata
					</Link>{" "}
					– through 360º
				</h3>
				<ThreeSixty
					tip="Swipe to rotate"
					className="mx-auto max-w-2xl rounded pb-[100%] md:pb-[700px]"
					image="https://assets.launer.com/images/360/3050-35/{i}_1280x1280.jpeg"
					count={36}
					loop
				/>
			</div>
			<Instagram className="mb-1 mt-14" />
		</div>
	);
}

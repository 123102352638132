import { Products, Row } from "@launerlondon/shop-components";
import { useMetaTags } from "@launerlondon/shop-hooks";
import { Link } from "react-router-dom";

export default function CollectionPartyBags() {
	useMetaTags({
		title: "Perfect Party Bags | Launer London",
		description:
			"The holiday season heralds a desire for undiluted glamour using gold, silver and patent. Our party bag edit in these dynamic finishes will ensure a stylish entrance with beloved styles, including Camelia, Iris, Grace and Faith.",
		image: "/images/collections/party-bags-banner.webp",
	});
	return (
		<div className="text-center">
			<Row.WithImage
				reverse
				image="/images/collections/party-bags-banner.webp"
				className="bg-gradient-to-r from-[#5D0611] to-[#4B090A] text-sm text-white"
				contentClassName="space-y-10 mb-4 grid place-items-center"
			>
				<h1 className="ln-title flex flex-col text-center text-4xl lg:max-w-[320px]">
					Perfect Party Bags
				</h1>
			</Row.WithImage>
			<Row.Single className="py-20">
				<p>
					The holiday season heralds a desire for undiluted glamour
					using gold, silver and patent. Our party bag edit in these
					dynamic finishes will ensure a stylish entrance with beloved
					styles, including Camelia, Iris, Grace and Faith.
				</p>
			</Row.Single>
			<Row.WithImage
				image="/images/collections/party-bags-iris.webp"
				className="bg-[#FEEDF1] text-[#746C6C]"
				contentClassName="space-y-8"
				imageLink="/p/3124-15/iris-gold"
			>
				<header className="space-y-2">
					<h2 className="ln-subtitle">Iris</h2>
					<p className="text-sm">Gold</p>
				</header>
				<p className="my-10 [&_a]:underline">
					At 18.5cm in length, Iris is delightfully slight, displaying
					the coveted Launer hallmarks: structured silhouette,
					immaculate detail and signature twisted rope emblem, now
					enlarged. It's a style of immense appeal, introduced in
					celebration of our 80th anniversary. Timeless, stylish and
					an investment in British craftsmanship.
				</p>
				<Link
					to="/p/3124-15/iris-gold"
					className="!mt-20 inline-block text-sm uppercase tracking-widest hover:text-[#4B090A]"
				>
					Shop now
				</Link>
			</Row.WithImage>
			<Products skus={["3051-07", "3104-01", "3099-02", "3051-01"]} />
			<Row.WithImage
				reverse
				image="/images/collections/party-bags-grace.webp"
				className="bg-[#FEEDF1] text-[#746C6C]"
				contentClassName="space-y-8"
				imageLink="/p/3104-01/grace-black-patent"
			>
				<header className="space-y-2">
					<h2 className="ln-subtitle">Grace</h2>
					<p className="text-sm">Black (Patent)</p>
				</header>
				<p className="my-10 [&_a]:underline">
					An exquisite clutch for evening elegance, highlighted by a
					stunning variation on the iconic Launer emblem, which on the
					Grace is embellished with shimmering crystal studs. Crafted
					from fine patent leather and lined with sumptuous suede,
					this quintessential clutch comes with the added flexibility
					of a detachable shoulder chain.
				</p>
				<Link
					to="/p/3104-01/grace-black-patent"
					className="!mt-20 inline-block text-sm uppercase tracking-widest hover:text-[#4B090A]"
				>
					Shop now
				</Link>
			</Row.WithImage>
			<Products skus={["2910-06", "3126-08", "3124-15", "3046-01"]} />
			<Row.WithImage
				image="/images/collections/party-bags-tosca.webp"
				className="bg-[#FEEDF1] text-[#746C6C]"
				contentClassName="space-y-8"
				imageLink="/p/2910-06/tosca-gold-croc-effect"
			>
				<header className="space-y-2">
					<h2 className="ln-subtitle">Tosca</h2>
					<p className="text-sm">Gold Croc Effect</p>
				</header>
				<p className="my-10 [&_a]:underline">
					A beautiful and beloved clutch bag that is ideal or special
					occasions for day or night. Tosca is now crafted in
					brilliant gold embossed mock croc to stunning effect. It
					retains its compact appearance and exquisite form, providing
					a style that is timeless with a detachable gold shoulder
					chain.
				</p>
				<Link
					to="/p/2910-06/tosca-gold-croc-effect"
					className="!mt-20 inline-block text-sm uppercase tracking-widest hover:text-[#4B090A]"
				>
					Shop now
				</Link>
			</Row.WithImage>
		</div>
	);
}

import { Localized } from "@launerlondon/l10n";
import { isCartDigital } from "@launerlondon/products";
import {
	defaultRoundFactor,
	fmtOrderRef,
	getOrderLastEvent,
	getOrderTotal,
} from "@launerlondon/shared";
import { Price, ProductImage } from "@launerlondon/shop-components";
import { AccountOrderListLoaderData, routes } from "@launerlondon/shop-router";
import { Suspense } from "react";
import {
	Await,
	generatePath,
	Link,
	useAsyncValue,
	useFetcher,
	useLoaderData,
} from "react-router-dom";

type Order = Awaited<AccountOrderListLoaderData["orders"]>[number];

const Order: React.FC<{ order: Order }> = ({ order }) => {
	const currency = order.payment.currency;
	const total = getOrderTotal(order, defaultRoundFactor).gross[currency];
	const event = getOrderLastEvent(order);
	const eventDate = new Date(
		(event.date as any)._seconds * 1000,
	).toLocaleDateString();
	const digital = isCartDigital(order.items).toString();
	return (
		<Link
			className="group rounded border text-center transition-colors"
			to={generatePath(routes.accountOrderView, { id: order.id })}
		>
			<h3 className="group-hover:text-accent-500 p-2 font-medium underline">
				Order #{fmtOrderRef(order.ref)}
			</h3>
			<div className="flex items-center justify-center overflow-auto py-4">
				{order.items.map((i, idx) => (
					<ProductImage key={idx} sku={i.sku} className="w-16 " />
				))}
			</div>
			<footer className="grid grid-cols-2 items-start gap-2 border-t bg-gray-50 p-2">
				<div className="flex flex-col items-center justify-center border-r">
					<h4 className="text-[10px] uppercase text-gray-400">
						status
					</h4>
					<Localized
						id={`email-status-${event.type}`}
						vars={{ digital }}
					/>
					<time className="block text-[11px] uppercase text-gray-400">
						{eventDate}
					</time>
				</div>
				<div className="flex flex-col items-center justify-center">
					<h4 className="text-[10px] uppercase text-gray-400">
						total
					</h4>
					<Price value={total} currency={currency} />
					<span className="block text-[11px] uppercase text-gray-400">
						{order.items.length}{" "}
						{order.items.length > 1 ? "items" : "item"}
					</span>
				</div>
			</footer>
		</Link>
	);
};

const Orders: React.FC = () => {
	const orders = useAsyncValue() as Order[];
	const { submit } = useFetcher();
	const pastOrders = orders.filter((o) =>
		["cancelled", "shipped", "refunded"].includes(o.status),
	);
	const currentOrders = orders.filter((o) => !pastOrders.includes(o));
	return (
		<div>
			<button
				className="mb-4 ml-auto block text-xs underline"
				onClick={() => submit({ refresh: "true" })}
			>
				Refresh orders
			</button>
			<div className="space-y-14">
				{[
					{ title: "Current orders", items: currentOrders },
					{ title: "Past orders", items: pastOrders },
				].map(({ title, items }) => (
					<div key={title}>
						<h3 className="top-0 my-4 bg-white py-2 text-center text-sm uppercase lg:sticky lg:border-b lg:text-left">
							{title}
						</h3>
						<div className="grid gap-4 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4">
							{!items.length && <h2>No orders so far...</h2>}
							{items.map((o) => (
								<Order key={o.id} order={o} />
							))}
						</div>
					</div>
				))}
			</div>
		</div>
	);
};

const AccountOrderList: React.FC = () => {
	const data = useLoaderData() as AccountOrderListLoaderData | undefined;
	return (
		<Suspense fallback="Loading your orders…">
			{data && (
				<Await resolve={data.orders}>
					<Orders />
				</Await>
			)}
		</Suspense>
	);
};

export default AccountOrderList;

import {
	Localized,
	getMenuLocaleId,
	useLocalization,
} from "@launerlondon/l10n";
import {
	ProductSnap,
	filterProductsByCategories,
	filterProductsBySKUs,
	filterProductsBySearchQuery,
	getCustomCollection,
	getProducts,
	hasCustomCollection,
} from "@launerlondon/products";
import { HeroBanner, ListViewControl } from "@launerlondon/shop-components";
import { useMetaTags, useProductListParams } from "@launerlondon/shop-hooks";
import { MarketingStatus, RootState } from "@launerlondon/shop-types";
import { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import meta from "../../assets/meta.json";
import { NewsletterSignup, ProductListSection } from "../components";
import MenuFilter from "../components/MenuFilter";
import { sanitizeSearchQuery } from "../lib/utils";

const productsLoading = [
	{
		name: "\u00A0",
		items: Array(6).fill({
			sku: "0000-00",
			slug: " ",
			name: "\u00A0–\u00A0",
			price: 0,
			categories: [],
			order: 0,
			thumb_product: { thumb: "", medium: "", large: "" },
			thumb_model: { thumb: "", medium: "", large: "" },
		}),
	},
];

const ProductList: React.FC = () => {
	const { slug, search, byStyle } = useProductListParams();
	const searchQuery = sanitizeSearchQuery(search);
	const l10n = useLocalization().l10n;
	const locale = useSelector((s: RootState) => s.locale);
	const [loading, setLoading] = useState(true);
	const [products, setProducts] = useState<ProductSnap[]>([]);
	const [ctxProducts, setCtxProducts] = useState<ProductSnap[]>([]);
	const [sections, setSections] =
		useState<{ name?: string; items: ProductSnap[] }[]>(productsLoading);
	const showPageTitle = ["women", "men", "wallets"].includes(String(slug));
	const showSearchTitle = !HeroBanner.keys.includes(searchQuery);

	const pageMeta = meta.find(
		(m) => m.path === `/c/${slug}` || m.path === `/s/${searchQuery}`,
	);

	useMetaTags(
		{
			suffix: false,
			title: pageMeta?.title || "Launer London",
			description: pageMeta?.description,
			image: HeroBanner.keys.includes(slug || searchQuery)
				? `/images/hero/${slug || searchQuery}.webp`
				: undefined,
		},
		[slug, searchQuery, byStyle],
	);

	useEffect(() => {
		setLoading(true);
		getProducts(locale.currency, locale.country).then((ps) => {
			setLoading(false);
			setProducts(ps.filter((p) => !p.private));
		});
	}, [locale.currency, locale.country]);

	useEffect(() => {
		if (!products.length) return;
		const f = searchQuery
			? filterProductsBySearchQuery(products, searchQuery)
			: hasCustomCollection(slug)
			? filterProductsBySKUs(
					products,
					getCustomCollection(slug)?.skus || [],
					byStyle,
			  )
			: filterProductsByCategories(products, [slug || ""], byStyle);

		setCtxProducts(f.flatMap((s) => s.items));
	}, [products, searchQuery, slug, byStyle]);

	useEffect(() => {
		if (!products.length || !searchQuery) return;
		setSections(filterProductsBySearchQuery(products, searchQuery));
	}, [products, searchQuery]);

	return (
		<>
			<HeroBanner.Header />
			<header className="container flex items-start justify-between gap-4 md:items-center">
				<MenuFilter
					hidePresetFilters
					products={ctxProducts}
					onChange={(v) => {
						// erase section name/slug when searching to avoid
						// adding specific content to search results
						if (searchQuery && v[0]) v[0].name = "";
						setSections(v);
					}}
				/>
				<ListViewControl className="my-4 md:my-0" />
			</header>
			{showPageTitle && (
				<h1 className="ln-title mx-auto -mb-10 mt-10">
					{l10n.getString(getMenuLocaleId(slug))}{" "}
					{byStyle && "by style"}
				</h1>
			)}

			{searchQuery && showSearchTitle && (
				<div className="m-10">
					<h1 className="ln-title py-4 text-center text-xl">
						<Localized
							id="product-list--search-title"
							vars={{ query: searchQuery }}
						/>
					</h1>
					<p className="warning m-auto mb-8 table rounded">
						<Localized
							id={
								sections.length
									? "product-list--search-disclaimer"
									: "product-list--search-empty"
							}
							html
						/>
					</p>
				</div>
			)}
			<div
				className={loading ? "isLoading" : undefined}
				style={{ minHeight: 700 }}
			>
				{sections.map((s, i) => {
					const title = s.name;
					const localeTitle = title
						? l10n.getString(getMenuLocaleId(title), {}, title)
						: undefined;
					const splitSection =
						sections.length > 1 &&
						Math.floor(sections.length / 2) === i;
					return (
						<Fragment key={i}>
							{splitSection && (
								<HeroBanner.Divider
									key={`divider-${i}`}
									className="md:container max-md:mx-4 md:my-10 md:px-0 lg:my-20"
								/>
							)}
							<ProductListSection
								slug={s.name}
								title={
									HeroBanner.keys.includes(title || "") &&
									sections.length === 1
										? undefined
										: localeTitle
								}
								titleTag={sections.length === 1 ? "h1" : "h2"}
								products={s.items}
								divider={
									sections.length === 1 ? (
										<HeroBanner.Divider />
									) : undefined
								}
							/>
						</Fragment>
					);
				})}
			</div>
			<HeroBanner.Footer />
			{!loading && (
				<NewsletterSignup
					showUnless={MarketingStatus.RefusedProductList}
					delay={3000}
				/>
			)}
		</>
	);
};

export default ProductList;

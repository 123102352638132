import { Products, Row } from "@launerlondon/shop-components";
import { useMetaTags } from "@launerlondon/shop-hooks";
import { Link } from "react-router-dom";

export default function CollectionRadiateGreen() {
	useMetaTags({
		title: "Radiate in Green This Winter | Launer London",
		description:
			"A joyous colour which evokes a sense of nature and the beauty of the English countryside. Our green hues are the perfect tonic at this time of year with deepest forest and emerald in leather and patent and now in faux embossed croc print – a touch of welcome vibrancy.",
		image: "/images/collections/radiate-green-banner.webp",
	});
	return (
		<div className="text-center">
			<Row.WithImage
				image="/images/collections/radiate-green-banner.webp"
				className="bg-gradient-to-r from-[#3D3F33] to-[#485111] text-sm text-white"
				contentClassName="space-y-10 mb-4 grid place-items-center"
			>
				<h1 className="ln-title flex flex-col text-center text-4xl lg:max-w-[500px]">
					Radiate in Green This Winter
				</h1>
			</Row.WithImage>
			<Row.Single className="py-20">
				<p>
					A joyous colour which evokes a sense of nature and the
					beauty of the English countryside. Our green hues are the
					perfect tonic at this time of year with deepest forest and
					emerald in leather and patent and now in faux embossed croc
					print – a touch of welcome vibrancy.
				</p>
			</Row.Single>
			<Row.WithImage
				image="/images/collections/radiate-green-anna.webp"
				className="bg-[#F3FAED] text-[#746C6C]"
				contentClassName="space-y-8"
				imageLink="/p/3085-03/anna-emerald"
			>
				<header className="space-y-2">
					<h2 className="ln-subtitle">Anna</h2>
					<p className="text-sm">Emerald</p>
				</header>
				<p className="my-10 [&_a]:underline">
					Immaculately proportioned with exquisite detail propels Anna
					into a handbag of stellar magnitude. A scaled down version
					of the best selling Olympia, Anna stands at 19cm in height
					and is remarkably sleek with two interior divisions, a
					zipped and two open pockets and features refined double
					handles.
				</p>
				<Link
					to="/p/3085-03/anna-emerald"
					className="!mt-20 inline-block text-sm uppercase tracking-widest hover:text-[#485111]"
				>
					Shop now
				</Link>
			</Row.WithImage>
			<Products skus={["3097-27", "3124-05", "685-09", "3050-37"]} />
			<Row.WithImage
				image="/images/collections/radiate-green-iris.webp"
				className="bg-[#F3FAED] text-[#746C6C]"
				contentClassName="space-y-8"
				imageLink="/p/3124-05/iris-emerald-brilliant-rose"
			>
				<header className="space-y-2">
					<h2 className="ln-subtitle">Iris</h2>
					<p className="text-sm">Emerald / Brilliant Rose</p>
				</header>
				<p className="my-10 [&_a]:underline">
					At 18.5cm in length, Iris is delightfully slight, displaying
					the coveted Launer hallmarks: structured silhouette,
					immaculate detail and signature twisted rope emblem, now
					enlarged. It's a style of immense appeal, introduced in
					celebration of our 80th anniversary. 
				</p>
				<Link
					to="/p/3124-05/iris-emerald-brilliant-rose"
					className="!mt-20 inline-block text-sm uppercase tracking-widest hover:text-[#485111]"
				>
					Shop now
				</Link>
			</Row.WithImage>
			<Products skus={["685-14", "2875-09", "3011-07", "3097-31"]} />
			<Row.WithImage
				reverse
				image="/images/collections/radiate-green-traviata.webp"
				className="bg-[#F3FAED] text-[#746C6C]"
				contentClassName="space-y-8"
				imageLink="/p/3050-36/traviata-forest-green-platinum-jubilee-tartan"
			>
				<header className="space-y-2">
					<h2 className="ln-subtitle">Traviata</h2>
					<p className="text-sm">
						Forest Green / Platinum Jubilee Tartan
					</p>
				</header>
				<p className="my-10 [&_a]:underline">
					This iconic style is now exclusively reimagined using a
					tartan with special permission from Royal Warrant holder
					Kinloch Anderson, combined with our house leather. It was
					created in celebration of Queen Elizabeth's Platinum Jubilee
					year in 2022. Chosen and approved by Queen Elizabeth, it is
					officially registered in The Scottish Register of Tartans.
				</p>
				<Link
					to="/p/3050-36/traviata-forest-green-platinum-jubilee-tartan"
					className="!mt-20 inline-block text-sm uppercase tracking-widest hover:text-[#485111]"
				>
					Shop now
				</Link>
			</Row.WithImage>
		</div>
	);
}
